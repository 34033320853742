<template>

  <v-container
      id="extended-tables"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <base-material-card
            color="success"
            icon="mdi-apps"
            title="Creating New Tenant"
            inline
        >
          <v-row>
            <v-col cols="10"></v-col>
            <v-col cols="2" class="text-right">
              <v-btn small right color="info" class="mr-0" @click="goToPage({route: 'Multi Tenancy'})">
                <v-icon small class="mr-1">
                  mdi-arrow-left
                </v-icon>
                Back
              </v-btn>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" md="12">
              <template v-if="loading">
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
              </template>
              <template v-else>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Name *"
                        :rules="[() => !!tenant.name || 'This field is required!']"
                        v-model="tenant.name"
                        counter
                        required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Description "
                        v-model="tenant.description"
                        counter
                        required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        :items="tenantTypes"
                        v-model="tenant.type"
                        item-text="label"
                        item-value="id"
                        label="Tenant Type *"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" v-if="tenant.type==='social'">
                    <v-select
                        :items="oauth2Strategies"
                        v-model="tenant.strategy"
                        item-text="label"
                        item-value="id"
                        label="Strategy *"
                        @change="checkSetCustom"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" v-if="custom">
                    <v-select
                        :items="customSettingsSocial"
                        v-model="tenant.customStrategy"
                        item-text="name"
                        item-value="name"
                        label="Custom Setting *"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        label="Caching TTL *"
                        v-model="tenant._TTL"
                        :rules="[() => !!tenant._TTL || 'This field is required!']"
                        required
                        item-text="text"
                        item-value="value"
                        :items="msCacheOptions"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="text-right pr-0 mr-0">
                    <v-btn
                        color="success"
                        v-if="canAccess({route: '/environments/:env/tenants', method: 'put'})"
                        @click="addTenant">
                      <v-icon class="mr-1">mdi-content-save</v-icon>
                      Create
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import globalMixins from "@/mixins/globalMixins";
import fieldsMixins from "./components/fieldAccess";

export default {
  name: 'addTenant',

  props: {
    envSelected: {
      type: Object
    }
  },

  mixins: [globalMixins, fieldsMixins],

  data() {
    return {
      fieldAccess: {},
      tenantTypes: [
        {
          id: 'general',
          label: 'General Tenant Application'
        },
        {
          id: 'social',
          label: 'Login with 3rd Party platform Tenant'
        },
        {
          id: 'ldap',
          label: 'Login with LDAP Server'
        }
      ],
      oauth2Strategies: [
        {
          id: 'google',
          label: 'Login with Google'
        },
        {
          id: 'facebook',
          label: 'Login with Facebook'
        },
        {
          id: 'linkedin',
          label: 'Login with LinkedIn'
        },
        {
          id: 'github',
          label: 'Login with Github'
        },
        {
          id: 'bitbucket',
          label: 'Login with Bitbucket'
        },
        {
          id: 'microsoft',
          label: 'Login with Microsoft'
        },
        {
          id: 'custom',
          label: 'Custom Driver'
        }
      ],
      custom: false,
      loading: true,
      tenant: {
        name: '',
        description: '',
        type: '',
        strategy: '',
        customStrategy: '',
        _TTL: null
      },
      customSettingsSocial: []
    }
  },

  created() {
    const _self = this;
    _self.fieldAccess = this.tenantCreateAccess();

    setTimeout(() => {
      _self.initialize();
    }, 1000)
  },

  methods: {

    checkSetCustom() {
      this.custom = this.tenant.strategy === 'custom';
    },

    initialize() {
      const _self = this;
      const apiOptions = {
        noLoading: true,
        url: `/consoleapi/environments/${this.envSelected.value}/social/custom-entries`,
        method: "get",
        params:{
          fields: ['name']
        }
      };

      this.getSendData(apiOptions).then((response) => {
        _self.customSettingsSocial = response.items;
        _self.loading = false;
      });

    },

    checkBasic(tenant) {
      this.clearMessages();
      if (!tenant.name || tenant.name.trim() === '') {
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'Field Name is required!'
        });
        return false;
      }

      if (!tenant.type || tenant.type.trim() === '') {
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'Field Tenant Type is required!'
        });
        return false;
      }

      if (tenant.type === 'social' && (!tenant.strategy || tenant.strategy.trim() === '')) {
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'Field Strategy is required!'
        });
        return false;
      }

      if(tenant.strategy && tenant.strategy === 'custom' && tenant.customStrategy.trim() === ''){
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'Field strategy is set to custom driver but no custom setting was chosen!'
        });
        return false;
      }

      if (!tenant._TTL) {
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'Field Caching TTL is required!'
        });
        return false;
      }

      return true;
    },

    addTenant() {
      if (!this.checkBasic(this.tenant)) {
        return false;
      }

      let payload = this._lodash.cloneDeep(this.tenant);
      switch (payload.type) {
        case 'social':
          payload.login = {
            "strategy": payload.strategy,
            "type": "oauth2",
            "client": {
              "id": "",
              "secret": ""
            },
            "register": null
          };

          if(payload.strategy === 'custom' && payload.customStrategy.trim() !== '' ){
            payload.login.strategy = payload.customStrategy;
          }
          break;
        case 'ldap':
          payload.login = {
            "strategy": "ldap",
            "type": "ldap",
            "client": {
              "url": [],
              "timeout": 0,
              "connectTimeout": 0,
              "reconnect": false
            },
            "scope": "",
            "base": "",
            "user": {
              "key": "",
              "fields": {
                "firstName": "",
                "lastName": "",
                "email": ""
              }
            },
            "register": null
          };
          break;
        default:
          payload.config = {
            specific: {},
            common: {},
          };
          break;
      }

      delete payload.type;
      delete payload.strategy;
      delete payload.customStrategy;

      let apiOptions = {
        url: `/consoleapi/environments/${this.envSelected.value}/tenants`,
        method: "put",
        params: payload
      };

      this.getSendData(apiOptions).then(() => {
        this.pushMessage({
          type: 'success',
          title: `Tenant created`,
          text: `The tenant ${payload.name} has been created!`
        });
        this.scrollToTop();

        setTimeout(() => {
          this.goToPage({route: 'Multi Tenancy'});
        }, 2000);
      });
    }
  }
}
</script>